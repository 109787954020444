import * as React from 'react';
import classNamesFn from 'classnames';
import { ResponsiveMenuContainerProps } from '../../../MenuContainer.types';
import styles from '../../styles/ResponsiveInlinePopup.scss';
import ResponsiveInlinePopupWithColorBG from '../ResponsiveInlinePopupWithColorBG';

const MenuContainer: React.FC<ResponsiveMenuContainerProps> = ({
  id,
  isOpen,
  isVisible,
  children,
  classNames,
  containerProps,
  onClick,
}) => {
  if (isOpen && !isVisible) {
    isVisible = true;
  }

  const layerIds = {
    overlay: `overlay-${id}`,
    container: `container-${id}`,
    inlineContentParent: `inlineContentParent-${id}`,
  };

  return (
    <div
      id={id}
      tabIndex={0}
      onClick={onClick}
      className={classNamesFn(
        styles.menuContainer,
        classNames.map(name => styles[name]),
        { [styles.visible]: isVisible, [styles.open]: isOpen },
      )}
    >
      <ResponsiveInlinePopupWithColorBG
        containerProps={containerProps}
        id={id}
        layerIds={layerIds}
        classNames={classNames}
      >
        {children}
      </ResponsiveInlinePopupWithColorBG>
    </div>
  );
};

export default MenuContainer;
