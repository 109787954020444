import * as React from 'react';
import classnames from 'classnames';
import { IRefComponentProps } from '../RefComponent.types';
import style from './styles/ResponsiveContainerRefSkin.scss';

const RefComponent: React.FC<IRefComponentProps> = props => {
  const {
    id,
    children,
    skin,
    containerRootClassName,
    tagName = 'div' as keyof JSX.IntrinsicElements,
  } = props;
  const classNames = classnames(style[skin], containerRootClassName);
  const TagName = tagName || ('div' as keyof JSX.IntrinsicElements);
  return (
    <TagName id={id} className={classNames}>
      {children()}
    </TagName>
  );
};

export default RefComponent;
