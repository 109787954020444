import * as React from 'react';
import { IPageSectionsProps } from '../PageSections.types';
import styles from './style/PageSections.scss';

const PageSections: React.FC<IPageSectionsProps> = ({ id, children }) => {
  return (
    <main id={id} className={styles.pageSections}>
      {children()}
    </main>
  );
};

export default PageSections;
